.xpbarContainer {
	position: relative;
	height: 32px;
}
.xpbarLevelScale {
	position: absolute;
	top: 34%;

	height: 8px;
	background-color: var(--background-additional-color);
	border-radius: 100px;
	border: 1px solid var(--border-color);
}
.xpbarLevelScaleFilled {
	position: absolute;
	top: 34%;

	height: 8px;
	background: linear-gradient(
		270.22deg,
		#7a6ad9 -10.57%,
		#aba1e9 12.5%,
		#dfdafb 38.45%,
		#cac2fc 60.56%,
		#9381ff 81.71%
	);
	border-radius: 100px;
	border: 1px solid var(--border-color);
}
.xpbarLevel {
	position: absolute;

	display: flex;
	justify-content: center;
	align-items: center;

	width: 32px;
	height: 32px;

	border-radius: 50%;
	background-color: var(--background-primary-color-inverse);
	color: var(--text-primary-color-inverse);
}
