.search-drawer-item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.search-drawer-item__img {
    width: 60px;
    height: 60px;
    object-fit: cover;
    border-radius: 10px;
}

.search-drawer-item__title {
    width: 200px;
}