.infobar {
	max-width: calc(var(--viewport-width) - var(--navbar-width));
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	align-items: center;
	height: 72px;
}

.infobar__search {
	width: 40px;
	height: 40px;
}

.infobar__button {
	width: 126px;
	height: 40px;
}

.infobar__logo {
	display: none;
}

.infobar__controls {
	display: flex;
	flex-direction: row;
}

.infobar__controls button {
	margin-right: 10px;
}

@media (max-width: 768px) {

	.infobar {
		max-width: 100vw;
		margin-left: 30px;
		justify-content: space-between;
	}

	.infobar__logo {
		display: block;
		width: 200px;
	}

}

@media (max-width: 481px) {
	.infobar {
		max-width: 100vw;
		margin-left: 10px;
		justify-content: space-between;
	}

	.infobar__logo {
		display: block;
		width: 160px;
	}
}
