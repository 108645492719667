.app {
    width: 100vw;
    height: 100vh;
    height:  -webkit-fill-available;
    display: grid;
    grid-template-columns: 1fr var(--navbar-width) minmax(320px, calc(var(--viewport-width) - var(--navbar-width))) 1fr;
    grid-template-rows: var(--infobar-height) 1fr;
    grid-template-areas: "sidebar sidebar navbar navbar"
                         "sidebar sidebar content .";
}

.navbar {
    grid-area: navbar;
}

.sidebar {
    grid-area: sidebar;
    background-color: var(--rs-sidenav-default-bg);
    display: flex;
    justify-content: flex-end;
}

.content {
    grid-area: content;
    padding-left: 20px;
}

/* Media query for narrow devices */
@media (max-width: 768px) {
    .app {
        grid-template-columns: 1fr;
        grid-template-rows: auto auto 1fr;
        grid-template-areas: "navbar"
                             "content"
                             "sidebar";
    }

    .sidebar {
        justify-content: center; /* Adjust alignment if necessary */
        position: fixed;
        bottom: 0;
        width: 100%;
        max-height: 80px;
        z-index: 1000; /* Ensure it stays above other content */
    }
}


