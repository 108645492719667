.page-columns-wrapper {
    display: flex;
    flex-wrap: nowrap;
}

.page-columns-wrapper__content {
    display: flex;
    flex-direction: column;
    flex: 1 1;
    padding: 20px 20px 20px 40px;
}

.page-columns-wrapper__right-column {
    width: 20%;
    padding: 20px;
    min-width: 310px;
}

@media (max-width: 768px) {
    .page-columns-wrapper__right-column {
        display: none;
    }

    .page-columns-wrapper__content {
        max-width: 100vw;
        padding: 20px;
    }
}

@media (max-width: 481px) {
    .page-columns-wrapper__content {
        padding: 15px;
        max-width: 100vw;
    }
}
