.leaderboardsPageContainer {
	min-height: calc(100% - var(--infobar-height));
	max-height: calc(100% - var(--infobar-height));
	display: flex;
	justify-content: space-between;

	padding-top: 15px;
}

.leaderboardsContent {
	width: 73%;
	height: 100%;

	padding-left: 15px;

	display: flex;
	flex-direction: column;
	gap: 24px;

	border-right: 1px solid var(--border-color);
}

.leaderboardsTitle {
	font: var(--font-l);
	font-weight: var(--font-weight-bold);
}

.leaderboardsTrendingContainer {
	padding-top: 5px;
}

.leaderboardsTagsRow {
	padding-top: 32px;
}
