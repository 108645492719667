.achievement {
    display: flex;
    align-items: center;
    opacity: 0.5;
    border-radius: 5px;
    border: #7a6ad9 solid 1px;
    height: 50px;
    gap: 10px;
    padding: 10px
}

.achievement__image {
    width: 30px;
    height: 30px;
}

.achievement__text {
    font-size: 1em;
}

.achievement--done {
    opacity: 1;
}