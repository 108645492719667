.memeTableRow {
	padding-top: 4px;
}

.meme-table {
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.memeFavButtonCell {
	display: flex;
	justify-content: center;
	align-items: center;
	color: var(--text-secondary-color);
	font-size: 24px;
}

.memeImageCell {
	width: 48px;
	height: 48px;
	border-radius: 5px;
	overflow: hidden;
	display: inline-block;
}
.memeTableLink {
	height: 100%;
	display: flex;
	align-items: center;
	padding-left: 10px;
}

.memeTableLink > a {
	color: var(--text-primary-color);
}
