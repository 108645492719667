.avatar-selector__left-column {
    width: 60%;
}
.avatar-selector__right-column {
    width: 40%;
}

.avatar-selector {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.avatar-selector__row {
    margin-bottom: 8px;
}

.avatar-selector__color-input-container {
    padding: 3px;
    border: 1px solid var(--background-active-color);
    border-radius: 5px;
    width: 224px;
    height: 35px;
}

.rsuite-color-picker-review {
    width: 224px;
    height: 35px;
    border-radius: 5px;
}

.avatar-selector__column {
    width: 48%;
    display: flex;
    flex-direction: column;
}
.avatar-selector__section {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.color-preview {
    width: 224px;
    height: 38px;
    border-radius: 5px;
}