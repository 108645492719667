.modal-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.login-modal__body {
    height: 250px;
}

.signup-modal__body {
    height: 250px;
}

.auth-modal__body {
    height: 250px;
}
