.meme-page {
	display: flex;
}

.meme-page__graph {
	flex-grow: 3;
}

.meme-page__info {
	flex-grow: 3;
}

.meme-page__header {
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.meme-page__header {
	padding: 10px;
}

.meme-page__title {
	margin-right: 10px;
}
