.shopItems {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr;
	justify-items: center;
	align-items: center;
	gap: 15px 12px;
}

.shopItemContainer {
	width: 230px;
	height: 170px;

	display: flex;
	flex-direction: column;
	justify-content: space-between;

	border: 1px solid var(--border-color);
	border-radius: 15px;
	padding: 10px;
}

.shopItemTitle {
}

.shopItemProduct {
	display: flex;
	align-items: center;
	justify-content: space-around;
}

.shopItemProduct img {
	width: 60px;
}

.shopItemButton {
	color: var(--text-primary-color-inverse);
	background-color: var(--background-primary-color-inverse);
}

.shop-page {
	padding: 20px 20px 20px 40px;
}

.shop-page__tags-row button {
	margin-right: 10px;
	margin-top: 10px;
	margin-bottom: 10px;
}
