.trending-meme-card {
	border: 1px solid #e0e0e0;
	border-radius: 15px;
	padding: 10px;
	width: 300px;
	height: 100px;
	background-color: #ffffff;
}

.trending-meme-card__title {
	font-size: 16px;
	color: var(--text-primary-color);
	/*white-space: nowrap;*/
	/*overflow: hidden;*/
	/*text-overflow: ellipsis;*/
	max-width: 200px;
}

.trending-meme-card__header {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	margin-bottom: 10px;
	width: 100%;
}

.trending-meme-card__image {
	width: 50px;
	height: 50px;
	border-radius: 15px;
	margin-right: 10px;
	border: var(--text-secondary-color) 1px solid;
}

.trending-meme-card__stats {
	display: flex;
	justify-content: flex-end;
	width: 100%;
}

.trending-meme-card__change {
	color: green;
	font-size: 12px;
	margin-right: 5px;
}

.trending-meme-card__value {
	font-size: 20px;
	margin-left: 10px;
	font-weight: bold;
	color: #4a4a4a;
}