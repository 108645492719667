.transaction-history {
    display: flex;
    flex-direction: column;
    padding: 10px;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.transaction-history-table {
    width: 100%;
}