.settingsPageContainer {
	min-height: calc(100% - var(--infobar-height));
	max-height: calc(100% - var(--infobar-height));
	display: flex;
	justify-content: space-between;

	padding-top: 15px;
}

.settingsPageContent {
	width: 73%;
	height: 100%;

	padding-left: 15px;

	display: flex;
	flex-direction: column;
}

.settingsPageTitle {
	font: var(--font-l);
	font-weight: var(--font-weight-bold);
}

.settingsPagePopover {
	margin-left: 5px;
}
