.listMenuContainer {
	display: flex;
	width: 100%;
	height: 104px;
	position: relative;
	justify-content: space-around;
}

.listMenuNavigation {
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	top: 25%;
	height: 48px;
	width: 48px;

	font: var(--font-xl);
	color: var(--text-primary-color);
	background-color: var(--background-secondary-color);

	border-radius: 50%;

	user-select: none;
	cursor: pointer;
}

.listMenuNavigation > div {
	width: 100%;
}

.listMenuNavigation.prev {
	left: 0%;
}

.listMenuNavigation.next {
	right: 0%;
}

.listMenuItems {
	width: 100%;
	display: flex;
	justify-content: space-between;
	padding-inline: 24px;
	list-style: none;
}

.listMenuItem a {
	text-decoration: none;
}
