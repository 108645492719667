.not-authorized-page {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.not-authorized-page__image {
    width: 500px;
    height: auto;
}

