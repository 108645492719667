.navbar__header {
	padding-left: 20px;
	padding-top: 32px;
	font: var(--font-l);
	font-weight: var(--font-weight-bold);
	color: var(--text-secondary-color);
}

.navbar__body {
	padding-top: 72px;
	display: flex;
	flex-direction: column;
}

.navbar__item-icon {
	font-size: 24px;
}

.navbar__link {
	color: var(--text-primary-color);
	display: flex;
	gap: 10px;
	align-items: center;
	text-decoration: none;
}


@media (max-width: 768px) {

	.navbar__header {
		display: none;
	}

	.navbar__body {
		flex-direction: row;
		justify-content: space-around;
		padding-top: 0;
	}

	.navbar__link {
		flex-direction: column;
		width: 50px;
		height: 50px;
		justify-content: center;
	}

	.navbar__inner-body {
		display: flex;
		flex-direction: row;
		width: 100%;
		justify-content: space-around
	}

	.navbar__link p {
		display: none;
	}

	.rs-sidenav-item-icon {
		width: 27px;
		height: 27px;
	}
}

@media (max-width: 481px) {
	.navbar__link {
		flex-direction: column;
		width: 50px;
		height: 50px;
		justify-content: center;
		padding: 0 !important;
	}

	.rs-sidenav-item-icon {
		width: 22px;
		height: 22px;
	}
}
