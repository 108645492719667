.user-display {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
}

.user-display__name {
    text-align: center;
}

.user-display__xp {
    width: 280px
}

.user-display__info {
    width: 100%
}