* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;

	scrollbar-width: thin;
	scrollbar-color: var(--background-primary-color-inverse) var(--border-color);
	text-decoration: none;
}

.rsuite-color-picker-overlay {
	z-index: 1000;
	position: absolute;
}

.rsuite-color-picker-review {
	border: solid 1px var(--border-color);
}

body {
	height:  -webkit-fill-available;
	height: 100vh;
}

a,
a:hover,
a:active,
a:visited,
a:focus {
	text-decoration: none;
}

/* для Chrome/Edge/Safari */
*::-webkit-scrollbar {
	height: 8px;
	width: 8px;
}

*::-webkit-scrollbar-track {
	background: var(--border-color);
}

*::-webkit-scrollbar-thumb {
	background-color: var(--background-primary-color-inverse);
	border-radius: 5px;
}

:root {
	/* Шрифты */
	--font-family-main: arial, helvetica, sans-serif;
	--font-family-title: "DM_Sans variant0", Tofu;
	--font-size-s: 12px;
	--font-line-s: 20px;
	--font-s: var(--font-size-s) / var(--font-line-s) var(--font-family-main);
	--font-size-m: 16px;
	--font-line-m: 24px;
	--font-m: var(--font-size-m) / var(--font-line-m) var(--font-family-main);
	--font-size-l: 32px;
	--font-line-l: 36px;
	--font-l: var(--font-size-l) / var(--font-line-l) var(--font-family-main);
	--font-size-xl: 32px;
	--font-line-xl: 40px;
	--font-xl: var(--font-size-xl) / var(--font-line-xl) var(--font-family-main);

	/* Вес шрифтов */
	--font-weight-normal: 500;
	--font-weight-bold: 700;

	/* Размеры */
	--navbar-width: 300px;
	--navbar-width-collapsed: 80px;
	--infobar-height: 70px;
	--viewport-width: 1900px;
	--right-bar-width: 300px;

	/* Цвета */
	--text-primary-color: #352e5b;
	--text-primary-color-inverse: #ffffff;
	--text-secondary-color: #9381ff;
	--text-placeholder-color: #cecece;
	--text-additional-color: #a69ed6;
	--background-primary-color: #ffffff;
	--background-primary-color-inverse: #9381ff;
	--background-secondary-color: #f8f7ff;
	--background-active-color: #a394ff;
	--background-additional-color: #eeebf4;

	--alert-color: #f06262;
	--success-color: #28ba72;
	--border-color: #d7d0ff;
	--transparent-color: #9481ff81;
	--shadow-color: #00000042;

	--premium-color: #FBB80B;
	--color-non-premium: #EDEDED;

	--sell-color:  #FF5252;
	--buy-color: #0BC556;

	/* Z индексы */
	--infobar-z-index: 10;

	/* RS переопределения */
	--rs-sidenav-default-text: var(--text-primary-color);
	--rs-sidenav-default-selected-text: var(--text-secondary-color);
	--rs-btn-primary-bg: var(--background-primary-color-inverse);
	--rs-btn-primary-hover-bg: var(--background-active-color);
	--rs-btn-primary-active-bg: var(--background-primary-color-inverse);
	--rs-btn-ghost-border: var(--background-primary-color-inverse);
	--rs-btn-ghost-text: var(--background-primary-color-inverse);
	--rs-btn-ghost-hover-border: var(--background-active-color);
	--rs-btn-ghost-hover-text: var(--background-active-color);
	--rs-btn-ghost-active-border: var(--background-primary-color-inverse);
	--rs-btn-ghost-active-text: var(--background-primary-color-inverse);
	--rs-carousel-bg: var(--background-primary-color)
}

.app {
	font: var(--font-m);
	font-weight: var(--font-weight-normal);
	color: var(--text-primary-color);
	display: flex;
}

.pageWrapper {
	/* padding: 20px; */
	flex-grow: 1;

	display: flex;
	flex-direction: column;
}

.userInfo {
	width: 27%;
	height: 100%;
	padding: 10px;
}

.rs-sidenav-nav>.rs-sidenav-item {
	padding-left: 24px;
	display: flex;
	align-items: center;
	gap: 15px;
}

.rs-table-header-row-wrapper {
	color: var(--text-primary-color);
}