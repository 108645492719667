.title {}

.title__s {
    font: var(--font-m);
    line-height: var(--font-line-m);
}

.title__m {
    font: var(--font-l);
    line-height: var(--font-line-l);

}

.title__l {
    line-height: var(--font-line-l);
    font: var(--font-l);
}

.title__bold {
    font-weight: var(--font-weight-bold);
}

.title__normal {
    font-weight: var(--font-weight-normal);
}

.title__primary {
    font-weight: var(--font-weight-bold);
    color: var(--text-secondary-color);
}

.title__ghost {
    font-weight: var(--font-weight-normal);
    color: var(--text-additional-color);
}

.title__default {
    font-weight: var(--font-weight-normal);
    color: var(--text-primary-color);
}