.portfolio-page {
    height: 100%;
}

.portfolio-page__grid {
    height: 100%;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-template-areas:
    'avatar chart chart stats'
    'memes memes achievements rose-chart';
    gap: 10px;
}

.portfolio-page-card {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 20px;
    max-height: 390px;
}

.portfolio-page__column-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}

.portfolio-page__avatar {
    grid-area: avatar;
}

.portfolio-page__meme-image {
    width: 30px;
    height: 30px;
    border-radius: 7px;
    border: var(--text-secondary-color) 1px solid;
}

.portfolio-page__battlepass-button {
    width: 90%;
    height: 40px;
    border-radius: 5px;
    background-color: var(--background-primary-color-inverse);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 10px;
    gap: 10px;
    color: var(--background-secondary-color)
}

.portfolio-page__battlepass-button svg {
    width: 30px;
    height: 30px;
}

.portfolio-page__chart {
    grid-area: chart;
}

.portfolio-page__zoom-button:hover {
    background-color: var(--background-active-color);
}

.portfolio-page__stats {
    grid-area: stats;
}

.portfolio-page__memes {
    grid-area: memes;
}

.portfolio-page__achievements {
    grid-area: achievements;
}

.portfolio-page__achievements-container {
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    gap: 5px;
}
.portfolio-page__rose-chart {
    grid-area: rose-chart;
}

/*.portfolio-page__table-container {*/
/*    display: flex;*/
/*    flex-direction: column;*/
/*    align-items: center;*/
/*}*/
