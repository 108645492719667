.info-popup {
    position: relative;
    display: inline-block;
}

.info-button {
    cursor: pointer;
    width: 20px;
    height: 20px;
}

.popup-content {
    font-size: 20px;
    position: absolute;
    top: 50%;
    left: 100%;
    transform: translateY(-50%);
    background-color: var(--background-primary-color);
    border: 1px solid var(--text-additional-color);
    border-radius: 5px;
    padding: 10px;
    box-shadow: 0 2px 3px var(--transparent-color);
    z-index: 100;
    width: 0;
    overflow: hidden;
    white-space: nowrap;
    transition: width 0.3s ease;
}

.info-popup .popup-content {
    width: auto; /* Устанавливаем ширину автоматически */
}
