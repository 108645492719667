.avatar-container {
	position: relative;
	border: 2px solid var(--color-non-premium);
}

.avatar-container--xs {
	width: 30px;
	height: 30px;
	border-radius: 50%;
}

.avatar-container--s {
	width: 60px;
	height: 60px;
	border-radius: 50%;
}

.avatar-container--m {
	width: 192px;
	height: 192px;
	border-radius: 50%;
}

.avatar-container--l {
	width: 512px;
	height: 512px;
	border-radius: 50%;
}

.avatar-container--xl {
	width: 100%;
	height: 500px;
	border: none;
	border-radius: 10px;
}

.avatar-container--full {
	width: 100%;
	height: 100%;
}

.avatar-container--premium {
	border: 2px solid var(--premium-color);
}


.avatar-layer {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;

}

.avatar-layer--xs {
	overflow: hidden;
	transform: translate(-30%, 120%) scale(4);
	clip-path: circle(14% at 57% 18.5%);
}

.avatar-layer--s {
	transform: translate(-17%, 60%) scale(2.5);
	overflow: hidden;
	clip-path: circle(22% at 56.5% 24%);
}

.avatar-layer--m {
	transform: scale(1.4) translate(0%, 10%);
	overflow: hidden;
	clip-path: circle(36% at 50% 40%);
}

.avatar-layer--l {
	transform: scale(1.4) translate(0%, 10%);
	overflow: hidden;
	clip-path: circle(36% at 50% 40%);
}

.avatar-layer--full {
	transform: scale(1.6);
	overflow: hidden;
}
