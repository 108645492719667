.transaction-modal {
    display: flex;
    flex-direction: column;
}

.transaction-modal__image {
    width: 150px;
    height: 150px;
    object-fit: cover;
    border-radius: 20px;
}

.transaction-modal__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 10px;
}

.transaction-modal__title {
    width: 50%;
    font-family: DM Sans, Serif;
}

.transaction-modal__quantity {
    display: flex;
    margin-bottom: 10px;
}

.transaction-modal__quantity-btn {
    padding: 8px 16px;
    font-size: 18px;
    background-color: #f0f0f0;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.transaction-modal__quantity-input {
    width: 60px;
    padding: 8px;
    font-size: 18px;
    text-align: center;
    border: 1px solid #ccc;
    border-radius: 20px;
    margin: 0 10px;
}

.transaction-modal__sell-btn {
    font-size: 18px;
    font-weight: bold;
    color: var(--text-primary-color-inverse);
    background-color: var(--sell-color);
    border: 1px solid var(--background-primary-color-inverse);
    border-radius: 20px;
    cursor: pointer;
}

.transaction-modal__buy-btn {
    font-size: 18px;
    font-weight: bold;
    color: var(--text-primary-color-inverse);
    background-color: var(--buy-color);
    border: 1px solid var(--background-primary-color-inverse);
    border-radius: 20px;
    cursor: pointer;
}
