.shopPackContainer {
	width: 100%;
	padding: 10px;

	display: flex;
	flex-direction: column;
	align-items: center;

	gap: 12px;

	border-radius: 15px;
	color: var(--text-primary-color-inverse);
}

.shopPackContainer.red {
	background-color: var(--alert-color);
}

.shopPackContainer.purple {
	background-color: var(--background-primary-color-inverse);
}

.shopPackTitle {
	font-weight: var(--font-weight-bold);
	font: var(--font-l);
}

.shopPackSubTitle {
	font: var(--font-m);
	text-align: center;
}

.shopPackItems {
	display: flex;
	flex-wrap: wrap;
	gap: 16px;
	row-gap: 8px;
}

.shopPackItem {
	width: 200px;
	height: 70px;

	display: flex;
	align-items: center;
	justify-content: space-between;

	background-color: var(--background-primary-color);
	border-radius: 15px;

	color: var(--text-primary-color);
}

.shopPackItem div {
	padding-left: 20px;
}

.shopPackItem img {
	margin-right: 20px;
	width: 60px;
}

.shopPackBuy span {
	padding-left: 10px;
	font: var(--font-l);
}

.shopPackButton {
	width: 200px;
}

.shopPackButton.red {
	color: var(--alert-color);
}

.shopPackButton.purple {
	color: var(--text-secondary-color);
}
