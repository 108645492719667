.meme-info__title {
    margin: 24px 0px;
}

.meme-info__buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.meme-info__picture  {
    width: 280px;
    height: 280px;
    object-fit: cover;
    border-radius: 15px;
}